/* ChartModal.css */
.ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .chart-container {
    width: 100%;
    min-width: 600px;
  }